export interface ProjectInfo {
  name: string
  description: string
  image: any
  skills: string[]
  sourceCodeUrl?: string
  previewUrl?: string
}

export const projects: ProjectInfo[] = [
  {
    name: 'EP2P',
    description: 'SaaS-сервис для P2P-арбитражников криптовалюты',
    image: require('./assets/projects/ep2p.png?as=webp'),
    skills: ['TypeScript', 'Vue.js', 'NestJS', 'PostgreSQL', 'Yandex Cloud'],
    previewUrl: 'https://app.ep2p.ru',
  },
  {
    name: 'CryptoCloud.js',
    description:
      'Обёртка для работы с API <a href="https://cryptocloud.plus" class="link" target="_blank">CryptoСloud</a>',
    image: require('./assets/projects/cryptocloud.jpeg?as=webp'),
    skills: ['TypeScript'],
    sourceCodeUrl: 'https://github.com/evanfokin/cryptocloud.js',
    previewUrl: 'https://www.npmjs.com/package/cryptocloud',
  },
]
