import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'devicon/devicon.min.css'
import { createApp } from 'vue'
import App from './App.vue'
import './styles/index.css'

import { faGithub, faTelegram } from '@fortawesome/free-brands-svg-icons'
import {
  faAddressCard,
  faArrowUp,
  faBars,
  faCode,
  faEnvelope,
  faMoon,
  faSun,
  faTimes,
  faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faSun,
  faMoon,
  faBars,
  faTimes,
  faArrowUp,
  faEnvelope,
  faAddressCard,
  faCode,
  faUpRightFromSquare,
  faGithub,
  faTelegram
)

createApp(App).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
